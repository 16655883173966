import { Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { ContactData } from '../interfaces/contact';
import { ContentApi } from '../api';
import { take, takeUntil } from 'rxjs/operators';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root',
})
export class ContactService implements OnDestroy{
    private destroy$: Subject<void> = new Subject();
    public contactData$: ReplaySubject<ContactData> = new ReplaySubject(1);
    // readonly data$: Observable<ContactData> = this.contactData$.asObservable();

  constructor(
      private api: ContentApi,
      private login: LoginService,
  ) {
      this.login.isLoggedIn$.pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.api.getContactData()
              .pipe(take(1))
              .subscribe(value => this.contactData$.next(value));
      })
  }

    ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
    }
}
