import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { RootComponent } from './components/root/root.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { LoginAsComponent } from './pages/login-as/login-as.component';
import { carsResolver } from './resolvers/cars.resolver';
import { CarsComponent } from './pages/cars/cars.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';


export const routes: Routes = [
    {
        path: '',
        component: RootComponent,
        data: {
            desktopHeader: 'pargo/one',
            mobileHeader: 'one',
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                loadChildren: () => import('./modules/home-two/home-two.module').then(m => m.HomeTwoModule),
            },
            {
                path: 'shop',
                loadChildren: () => import('./modules/shop/shop.module').then(m => m.ShopModule),
            },
            {
                path: 'blog',
                loadChildren: () => import('./modules/blog/blog.module').then(m => m.BlogModule),
            },
            {
                path: 'account',
                loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule),
            },
            {
                path: 'site',
                loadChildren: () => import('./modules/site/site.module').then(m => m.SiteModule),
            },
            {
                path: 'vehicle',
                loadChildren: () => import('./modules/vehicle/vehicle.module').then(m => m.VehicleModule),
            },
            {
                path: 'las/:token',
                component: LoginAsComponent,
            },
            {
                path: 'las/:token/:navigate',
                component: LoginAsComponent,
            },
            {
                path: 'forgot-password',
                component: ForgotPasswordComponent,
            },
            {
                path: 'reset-password/:token',
                component: ResetPasswordComponent,
            },
            {
                path: 'cars',
                component: CarsComponent,
                resolve: {
                    cars: carsResolver,
                },
            },
            {
                path: '404',
                component: PageNotFoundComponent,
            },
            {
                path: '**',
                component: PageNotFoundComponent,
            },
        ],
    },
];


@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'disabled',
            anchorScrolling: 'disabled',
            initialNavigation: 'disabled',
            preloadingStrategy: PreloadAllModules,
            onSameUrlNavigation: 'reload',
        }),
    ],
    exports: [
        RouterModule,
    ],
})
export class AppRoutingModule {
}
