import { Component, OnInit } from '@angular/core';
import { BlockSpaceComponent } from '../../modules/shared/components/block-space/block-space.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AccountApi } from '../../api';
import { take, catchError } from 'rxjs/operators';
import { NgIf } from '@angular/common';
import { IconComponent } from '../../modules/shared/components/icon/icon.component';
import { HasErrorPipe } from '../../modules/shared/pipes/has-error.pipe';
import { IsInvalidPipe } from '../../modules/shared/pipes/is-invalid.pipe';
import { throwError } from 'rxjs';
import { RouteMetaService } from '../../services/route-meta.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    standalone: true,
    imports: [
        BlockSpaceComponent,
        TranslateModule,
        ReactiveFormsModule,
        NgIf,
        IconComponent,
        HasErrorPipe,
        IsInvalidPipe,
    ],
})
export class ForgotPasswordComponent implements OnInit {
    forgotPasswordForm: FormGroup;
    success = false;
    errorMessage: string | null = null;

    constructor(
        private fb: FormBuilder,
        private account: AccountApi,
        private meta: RouteMetaService,
    ) {
        this.forgotPasswordForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
        });
    }

    ngOnInit(): void {
        this.meta.update({
            title: this.meta.generateTitle('TEXT_PASSWORD_RECOVERY'),
            keywords: [],
            description: '',
        });
    }

    onSubmit() {
        this.success = false;
        this.errorMessage = null;
        if (this.forgotPasswordForm.valid) {
            this.account.forgotPassword(this.forgotPasswordForm.value.email)
                .pipe(
                    take(1),
                    catchError(error => {
                        this.errorMessage = `ERROR_API_${error.error.message}`
                        return throwError(()=>new Error(error));
                    }),
                )
                .subscribe(value => {
                    console.log('forgot pass', value)
                    this.success = true;
                })
        }
    }
}
