<!--<a class="mobile-menu__indicator" routerLink="/shop/wishlist" (click)="menu.close()">-->
<!--    <span class="mobile-menu__indicator-icon">-->
<!--        <app-icon icon="heart-20"></app-icon>-->
<!--        <span class="mobile-menu__indicator-counter" *ngIf="wishlist.count$|async">{{ wishlist.count$|async }}</span>-->
<!--    </span>-->
<!--    <span class="mobile-menu__indicator-title">{{ 'TEXT_MOBILE_INDICATOR_WISHLIST'|translate }}</span>-->
<!--</a>-->
<a *ngIf="loginService.isLoggedIn$|async" class="mobile-menu__indicator" [routerLink]="'/account'|localize" (click)="menu.close()">
    <span class="mobile-menu__indicator-icon">
        <app-icon icon="person-20"></app-icon>
    </span>
    <span class="mobile-menu__indicator-title">{{ 'TEXT_MOBILE_INDICATOR_ACCOUNT'|translate }}</span>
</a>
<a class="mobile-menu__indicator" [routerLink]="'/shop/cart'|localize" (click)="menu.close()">
    <span class="mobile-menu__indicator-icon">
        <app-icon icon="cart-20"></app-icon>
        <span class="mobile-menu__indicator-counter" *ngIf="cart.quantity$|async">{{ cart.quantity$|async }}</span>
    </span>
    <span class="mobile-menu__indicator-title">{{ 'TEXT_MOBILE_INDICATOR_CART'|translate }}</span>
</a>
<a class="mobile-menu__indicator" [routerLink]="'/account/garage'|localize" (click)="menu.close()">
    <span class="mobile-menu__indicator-icon">
        <app-icon icon="car-20"></app-icon>
    </span>
    <span class="mobile-menu__indicator-title">{{ 'TEXT_MOBILE_INDICATOR_GARAGE'|translate }}</span>
</a>
