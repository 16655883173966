import { Injectable } from '@angular/core';
import { VehicleApi } from '../base';
import { Observable } from 'rxjs';
import {
    BaseVehicle,
    FeaturedCars,
    ModificationVehicle,
    SearchVehicle,
    UserVehicle,
    Vehicle,
} from '../../interfaces/vehicle';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LanguageService } from '../../modules/language/services/language.service';

@Injectable()
export class RestVehicleApi extends VehicleApi {

    private url = environment.apiHost;

    private get lang(): string {
        return `lan=${this.language.current.code}`;
    }

    constructor(
        private http: HttpClient,
        private language: LanguageService,
    ) {
        super();
    }

    // getYears(): Observable<number[]> {
    //     return getYears();
    // }

    getYearsByMake(make: number): Observable<BaseVehicle[]> {
        const url = `${this.url}/vehicle/make-year?${this.lang}`;
        const params = new HttpParams()
            .set('mfrId', make);
        return this.http.get<BaseVehicle[]>(url, { params: params });
    }

    getSeries(year: number, make: number): Observable<BaseVehicle[]> {
        const url = `${this.url}/vehicle/model-series?${this.lang}`;
        const params = new HttpParams()
            .set('mfrId', make)
            .set('year', year);
        return this.http.get<BaseVehicle[]>(url, { params: params });
    }

    getVehicles(year: number, make: number, series: number): Observable<Vehicle[]> {
        const url = `${this.url}/vehicle/target-model?${this.lang}`;
        const params = new HttpParams()
            .set('mfrId', make)
            .set('year', year)
            .set('seriesId', series);
        return this.http.get<Vehicle[]>(url, { params: params });
    }

    getManufacturer(): Observable<BaseVehicle[]> {
        const url = `${this.url}/vehicle/brands?${this.lang}`;
        return this.http.get<BaseVehicle[]>(url);
    }

    getModels(brandId: number): Observable<BaseVehicle[]> {
        const url = `${this.url}/vehicle/models?${this.lang}`;
        const params = new HttpParams()
            .set('brandId', brandId)
        return this.http.get<BaseVehicle[]>(url, { params: params });
    }

    getModifications(brandId: number, modelId: number): Observable<ModificationVehicle[]> {
        const url = `${this.url}/vehicle/modifications?${this.lang}`;
        const params = new HttpParams()
            .set('brandId', brandId)
            .set('modelId', modelId)
        return this.http.get<ModificationVehicle[]>(url, { params: params });
    }

    getGarage(): Observable<UserVehicle[]> {
        const url = `${this.url}/vehicle/garage?${this.lang}`;
        return this.http.get<UserVehicle[]>(url);
    }

    addVehicle(id: number, uid: string, vin: string, plate: string): Observable<any> {
        const url = `${this.url}/vehicle/vehicle`;
        const params = new HttpParams()
            .set('id', id)
            .set('uid', uid)
            .set('vin', vin)
            .set('plate', plate);
        return this.http.put<any>(url, {}, { params: params });
    }

    removeVehicle(id: number, uid: string): Observable<any> {
        const url = `${this.url}/vehicle/vehicle`;
        const params = new HttpParams()
            .set('id', id)
            .set('uid', uid);
        return this.http.delete<any>(url, { params: params });
    }

    getVehicle(id: number, uid: string): Observable<UserVehicle> {
        const url = `${this.url}/vehicle/vehicle`;
        const params = new HttpParams()
            .set('id', id)
            .set('uid', uid);
        return this.http.get<UserVehicle>(url, { params: params });
    }

    saveUserCarData(car:UserVehicle): Observable<any> {
        const url = `${this.url}/vehicle/save-user-car-data`;
        const params = new HttpParams()
            .set('uid', car.uid);
        const body = {
            vin: car.vin,
            plate: car.plate,
        };
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post<any>(url, JSON.stringify(body), { params: params, headers: headers });
    }

    /**
     * Retrieves a vehicle by its VIN.
     *
     * @param {string} vin - The VIN of the vehicle to retrieve.
     * @return {Observable<SearchVehicle>} - An observable that emits the search vehicle object.
     */
    getVehicleByVIN(vin: string): Observable<SearchVehicle> {
        const url = `${this.url}/vehicle/vehicle-by-vin`;
        const params = new HttpParams()
            .set('vin', vin);
        return this.http.get<SearchVehicle>(url, { params: params });
    }

    getVehicleByPlate(plate: string): Observable<SearchVehicle> {
        const url = `${this.url}/vehicle/vehicle-by-plate`;
        const params = new HttpParams()
            .set('plate', plate);
        return this.http.get<SearchVehicle>(url, { params: params });
    }

    /**
     * Retrieves the list of featured cars.
     *
     * @returns {Observable<FeaturedCars>} An observable that emits the list of featured cars.
     */
    getFeaturedCars(): Observable<FeaturedCars> {
        const url = `${this.url}/vehicle/featured-cars`;
        return this.http.get<FeaturedCars>(url);
    }

    getBrand(slug: string): Observable<BaseVehicle> {
        const url = `${this.url}/vehicle/brand`;
        const params = new HttpParams()
            .set('slug', slug);
        return this.http.get<BaseVehicle>(url, { params: params });
    }

    getModel(slug: string): Observable<BaseVehicle> {
        const url = `${this.url}/vehicle/model`;
        const params = new HttpParams()
            .set('slug', slug);
        return this.http.get<BaseVehicle>(url, { params: params });
    }

    getCar(targetKeyword: string): Observable<ModificationVehicle> {
        const url = `${this.url}/vehicle/car`;
        const params = new HttpParams()
            .set('index', targetKeyword);
        return this.http.get<ModificationVehicle>(url, { params: params });
    }
}
