import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { defer, merge, Observable, of } from 'rxjs';
import { Language, LanguageDirection, LANGUAGES } from '../interfaces/language';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class LanguageService {
    readonly current$: Observable<Language>;

    readonly currentChange$: Observable<Language>;

    readonly direction$: Observable<LanguageDirection>;

    readonly directionChange$: Observable<LanguageDirection>;

    get all(): Language[] { return this.languages; }

    get default(): Language {
        const language = this.languages.find(x => x.code === this.translate.defaultLang);

        if (!language) {
            throw new Error('Language not found');
        }

        return language;
    }

    get current(): Language {
        return this.languages.find(x => x.code === this.translate.currentLang) || this.default;
    }

    constructor(
        private translate: TranslateService,
        @Inject(LANGUAGES) private languages: Language[],
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        this.currentChange$ = this.translate.onLangChange.pipe(
            map(event => {
                const language = this.languages.find(x => x.code === event.lang);

                if (!language) {
                    throw new Error('Language not found');
                }

                return language;
            }),
        );
        this.current$ = merge(
            defer(() => of(this.current)),
            this.currentChange$,
        );

        this.directionChange$ = this.currentChange$.pipe(
            map(x => x.direction),
            distinctUntilChanged(),
        );
        this.direction$ = this.current$.pipe(
            map(x => x.direction),
            distinctUntilChanged(),
        );
    }

    set(code: string): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('plang', code);
        }
        // if (this.current.code === code) {
        //     return;
        // }
        //
        // this.translate.use(code);
    }

    isRTL(): boolean {
        return this.current.direction === 'rtl';
    }
}
