<div class="container">
    <div class="car-picker d-flex flex-column gap-2rem">

        <div class="d-flex flex-column gap-1rem">
            <div *ngIf="showPickerLabel" class="h5">{{(featured ? 'TEXT_FEATURED_CAR_BRAND' : 'TEXT_SELECT_CAR_BRAND')|translate}}</div>
            <div class="car-picker--grid">
                <a [routerLink]="url.car(brand, undefined, category, productBrand) | localize" class="car-picker--item"
                   *ngFor="let brand of allBrands">
                    <span>{{ brand.name }}</span>
                </a>
                <a *ngIf="featured" [routerLink]="'/cars'|localize" class="car-picker--item">
                    <span>{{ 'TEXT_ALL_CAR_BRANDS'|translate }}</span>
                </a>
            </div>
        </div>

        <div *ngIf="modelPicker" class="d-flex flex-column gap-1rem">
            <div *ngIf="showPickerLabel" class="h5 pt-3">{{(featured ? 'TEXT_FEATURED_CAR_MODEL' : 'TEXT_SELECT_CAR_MODEL')|translate}}</div>
            <div class="car-picker--letters pb-4">
                <span
                    *ngFor="let letter of brandLetters"
                    class="mx-1"
                    (click)="setCurrentLetter(letter)"
                    [class.active]="currentLetter === letter"
                >
                    {{ letter|uppercase }}
                </span>
            </div>
            <div class="d-flex flex-column gap-2rem">
                <ng-container *ngIf="currentLetter">
                    <div
                        *ngFor="let brand of getBrandCars(currentLetter)"
                        class="car-picker--brand"
                    >
                        <div class="h5 pb-3 text-secondary font-weight-bold">{{ brand.name }}</div>
                        <div class="car-picker--grid">
                            <a [routerLink]="url.car(brand, model, category, productBrand) | localize" class="car-picker--item"
                               *ngFor="let model of brand.models">
                                <span>{{ model.name }}</span>
                            </a>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

    </div>
</div>
