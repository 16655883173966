import { Observable } from 'rxjs';
import { Order } from '../../interfaces/order';
import { Product } from '../../interfaces/product';

export interface CheckoutFormData {
    deliveryMethods: DeliveryMethod[];
    pickupPoints: PickupPoint[];
    contact: Person;
    recipients: Person[];
    payers: {
        fop: PayerOrganization[],
        org: PayerOrganization[];
    },
    lastData?: LastCheckoutData,
}

export interface Person {
    id?: number;
    firstName: string;
    lastName: string;
    additionalName?: string;
    phone: string;
    email?: string;
}

export interface DeliveryMethod {
    id: number;
    name: string;
    code: string;
    comment: string;
    sort: number;
    image: string;
    paymentServices: PaymentService[];
}

export interface PaymentService {
    id: number;
    code: string;
    name: string;
    params: string;
    sort: number;
    comment: string;
    icons: string[];
}

export interface PickupPoint {
    id: number;
    name: string;
    address: string;
    partner: boolean;
    description: string;
}

export interface DeliveryPoint {
    name: string;
    ref: string;
}

export interface DeliveryWarehouse extends DeliveryPoint {
    lat: string;
    lon: string;
}

export interface CheckoutFormValue {
    contactFirstName: string;
    contactLastName: string;
    contactPhone: string;
    contactEmail: string;

    deliveryMethod: number;
    pickupPoint: number;
    region: DeliveryPoint | null;
    city: DeliveryPoint | null;
    warehouse: DeliveryWarehouse | null;
    street: DeliveryPoint | null;
    house: string;
    apartment: string;

    paymentService: number;
    payerTaxIdentNumber: string;
    payerEdrpou: string;
    payerOrganization: string;
    payerCustomerId: string;
    payerAgreementId: string;

    recipientFirstName: string;
    recipientAdditionalName: string;
    recipientLastName: string;
    recipientPhone: string;

    comment: string;
}

export interface CheckoutItemOptionData {
    name: string;
    value: string;
}


export interface CheckoutItemData {
    productId: number;
    options: CheckoutItemOptionData[];
    quantity: number;
    price: number;
}

export interface CheckoutData {
    items: CheckoutItemData[];
    formValue: CheckoutFormValue;
}

export interface Payer {
    success: boolean;
    error: string;
    name: string;
    agreement: string;
    customer: string;
    isB2B: boolean;
}

export interface PayerOrganization {
    name: string;
    code: string;
    orgName: string;
}

export interface LastCheckoutData {
    paymentId: number;
    payment: string;
    payerEdrpou: string;
    payerItn: string;
    payerOrg: string;
    payerCustomer: string;
    payerAgreement: string;
    methodId: number;
    method: string;
    point: number;
    region: string;
    regionRef: string;
    city: string;
    cityRef: string;
    warehouse: string;
    warehouseRef: string;
    street: string;
    streetRef: string;
    house: string;
    apartment: string;
}

export interface CorrectCheckoutItem {
    Id: number,
    Amount_1c: number,
    Price_1c: number,
}

export interface CheckoutUpdateItems {
    success: boolean;
    error: string;
    items: CorrectCheckoutItem[];
}

export interface CheckoutOrder {
    success: boolean;
    error: string;
    order: Order;
}

export interface CartData {
    items: CartItem[];
    quantity: number;
    subtotal: number;
    totals: CartTotal[];
    total: number;
    noSelectUpdate?: boolean;
}

export interface CartTotal {
    title: string;
    price: number;
    type: 'shipping'|'fee'|'tax'|'other';
}

export interface CartItem {
    product: Product;
    options: {
        name: string;
        value: string;
    }[];
    selectForCheckout: boolean;
    quantity: number;
    quantityOld: number;
}

export interface CalcCartData {
    items: {
        id: number,
        price: number;
        quantity: number;
        oldQuantity: number;
    }[],
    subtotal: number;
    quantity: number;
}

export abstract class CheckoutApi {
    abstract getFormData(): Observable<CheckoutFormData>;

    abstract getStreets(term: string, methodCode: string, { cityRef = '' }): Observable<DeliveryPoint[]>;

    abstract getRegions(service: string): Observable<DeliveryPoint[]>;

    abstract getCities(service: string, { term = '', refRegion = '' }): Observable<DeliveryPoint[]>;

    abstract getWarehouses(cityRef: string, methodCode: string): Observable<DeliveryWarehouse[]>;

    abstract getPayer(value: string, field: string): Observable<Payer>;

    abstract checkout(data: CheckoutData): Observable<CheckoutOrder | CheckoutUpdateItems>;

    abstract cart(data: CartData): Observable<CartData>;

    abstract deleteCartItems(items: CartItem[]): Observable<CartData>;

    abstract addCartItem(item: CartItem): Observable<CartData>;

    abstract updateCartItems(items: CartItem[]): Observable<CartData>;

    abstract calcCart(items: CartItem[]): Observable<CalcCartData>;
}
