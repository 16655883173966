<div class="sign-form-logo d-none d-lg-flex">
    <img [src]="'assets/images/logo_full.png'" class="img-fluid" alt="" />
</div>
<div class="sign-form-content p-3 px-sm-5 py-sm-4">
    <ul class="sign-form-content__list">
        <li
            class="sign-form-content__item"
            [class.sign-form-content__item--active]="currentTab === tabSignIn"
        >
            <span (click)="setCurrentTab(tabSignIn)">{{
                "HEADER_LOGIN" | translate | uppercase
            }}</span>
        </li>
        <li
            class="sign-form-content__item"
            [class.sign-form-content__item--active]="currentTab === tabSignUp"
        >
            <span (click)="setCurrentTab(tabSignUp)">{{
                "HEADER_REGISTER" | translate | uppercase
            }}</span>
        </li>
    </ul>

    <div class="sign-form-content__body">
        <ng-container *ngIf="currentTab === tabSignIn">
            <h2 class="sign-form-content__title">
                {{ "HEADER_LOGIN" | translate | uppercase }}
            </h2>
            <form [formGroup]="loginForm">
                <div
                    *ngIf="loginForm.errors && loginForm.errors['server']"
                    class="alert alert-sm alert-danger"
                >
                    {{
                        (loginForm.errors && loginForm.errors["server"]) || ""
                            | translate
                    }}
                </div>
                <div class="form-group">
                    <label class="sign-form-label" for="signin-email">{{
                        "INPUT_EMAIL_ADDRESS_LABEL" | translate
                    }}</label>
                    <input
                        id="signin-email"
                        type="email"
                        class="form-control"
                        [class.is-invalid]="
                            loginForm.controls['email'] | isInvalid
                        "
                        placeholder="customer@example.com"
                        formControlName="email"
                    />
                    <div class="invalid-feedback">
                        <div
                            *ngIf="
                                loginForm.controls['email']
                                    | hasError : 'required'
                            "
                        >
                            {{ "ERROR_FORM_REQUIRED" | translate }}
                        </div>
                        <div
                            *ngIf="
                                loginForm.controls['email'] | hasError : 'email'
                            "
                        >
                            {{ "ERROR_FORM_INCORRECT_EMAIL" | translate }}
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="sign-form-label" for="signin-password">{{
                        "INPUT_PASSWORD_LABEL" | translate
                    }}</label>
                    <div
                        class="sign-form__password-container"
                        [class.is-invalid]="
                            loginForm.controls['password'] | isInvalid
                        "
                    >
                        <input
                            id="signin-password"
                            [type]="showSignInPassword ? 'text' : 'password'"
                            class="form-control"
                            [class.is-invalid]="
                                loginForm.controls['password'] | isInvalid
                            "
                            [placeholder]="
                                'INPUT_PASSWORD_PLACEHOLDER' | translate
                            "
                            formControlName="password"
                        />
                        <button
                            type="button"
                            class="sign-form__toggle-password"
                            (click)="toggleSignInPasswordVisibility()"
                        >
                            <fa-icon
                                *ngIf="!showSignInPassword"
                                [icon]="faEye"
                            ></fa-icon>
                            <fa-icon
                                *ngIf="showSignInPassword"
                                [icon]="faEyeSlash"
                            ></fa-icon>
                        </button>
                    </div>
                    <div class="invalid-feedback">
                        <div
                            *ngIf="
                                loginForm.controls['password']
                                    | hasError : 'required'
                            "
                        >
                            {{ "ERROR_FORM_REQUIRED" | translate }}
                        </div>
                    </div>
                </div>
                <div class="form-group sign-form-row justify-content-end">
                    <!--                    <div class="form-check">-->
                    <!--                        <app-checkbox-->
                    <!--                                class="form-check-input"-->
                    <!--                                formControlName="remember"-->
                    <!--                                #cbSignInRemember="appCheckbox"-->
                    <!--                        ></app-checkbox>-->
                    <!--                        <label class="form-check-label" [htmlFor]="cbSignInRemember.inputId">-->
                    <!--                            {{ 'INPUT_REMEMBER_ME_LABEL'|translate }}-->
                    <!--                        </label>-->
                    <!--                    </div>-->
                    <div class="form-text sign-form-link">
                        <a
                            (click)="modalService.close()"
                            [routerLink]="'/forgot-password' | localize"
                            >{{ "LINK_FORGOT_PASSWORD" | translate }}</a
                        >
                    </div>
                </div>
                <div class="form-group mb-0">
                    <button
                        type="submit"
                        class="btn btn-primary mt-4 mb-4 sign-form__submit-button"
                        [class.btn-loading]="loginInProgress"
                        (click)="login()"
                    >
                        {{ "BUTTON_LOGIN" | translate | uppercase }}
                    </button>
                </div>
            </form>
            <div class="sign-form__divider">
                {{ "TEXT_OR_SIGN_IN" | translate | uppercase }}
            </div>
            <div class="sign-form__service-buttons">
                <!--                <a href="/">-->
                <!--                    <app-icon [icon]="'google-23'"></app-icon>-->
                <!--                </a>-->
                <asl-google-signin-button
                    type="standard"
                    size="medium"
                    shape="pill"
                    theme="outline"
                    logo_alignment="center"
                ></asl-google-signin-button>
                <!--                <a href="/">-->
                <!--                    <app-icon [icon]="'facebook-23'"></app-icon>-->
                <!--                </a>-->
            </div>
        </ng-container>

        <ng-container *ngIf="currentTab === tabSignUp">
            <h2 class="sign-form-content__title">
                {{ "HEADER_REGISTER" | translate | uppercase }}
            </h2>
            <form [formGroup]="registerForm">
                <div
                    *ngIf="registerForm.errors && registerForm.errors['server']"
                    class="alert alert-sm alert-danger"
                >
                    {{
                        (registerForm.errors &&
                            registerForm.errors["server"]) ||
                            "" | translate
                    }}
                </div>

                <div class="form-group">
                    <label class="sign-form-label" for="signup-name">{{
                        "INPUT_FIRST_NAME_LABEL" | translate
                    }}</label>
                    <input
                        id="signup-name"
                        type="text"
                        class="form-control"
                        [class.is-invalid]="
                            registerForm.controls['firstName'] | isInvalid
                        "
                        [placeholder]="
                            'INPUT_FIRST_NAME_PLACEHOLDER'
                                | translate
                                | uppercase
                        "
                        formControlName="firstName"
                    />
                    <div class="invalid-feedback">
                        <div
                            *ngIf="
                                registerForm.controls['firstName']
                                    | hasError : 'required'
                            "
                        >
                            {{ "ERROR_FORM_REQUIRED" | translate }}
                        </div>
                        <div
                            *ngIf="
                                registerForm.controls['firstName']
                                    | hasError : 'firstName'
                            "
                        >
                            {{ "ERROR_FORM_INCORRECT_FIRST_NAME" | translate }}
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="sign-form-label" for="signup-lastname">{{
                        "INPUT_LAST_NAME_LABEL" | translate
                    }}</label>
                    <input
                        id="signup-lastname"
                        type="text"
                        class="form-control"
                        [class.is-invalid]="
                            registerForm.controls['lastName'] | isInvalid
                        "
                        [placeholder]="
                            'INPUT_LAST_NAME_PLACEHOLDER'
                                | translate
                                | uppercase
                        "
                        formControlName="lastName"
                    />
                    <div class="invalid-feedback">
                        <div
                            *ngIf="
                                registerForm.controls['lastName']
                                    | hasError : 'required'
                            "
                        >
                            {{ "ERROR_FORM_REQUIRED" | translate }}
                        </div>
                        <div
                            *ngIf="
                                registerForm.controls['lastName']
                                    | hasError : 'lastName'
                            "
                        >
                            {{ "ERROR_FORM_INCORRECT_LAST_NAME" | translate }}
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="sign-form-label" for="signup-phone">{{
                        "INPUT_PHONE_NUMBER_LABEL" | translate
                    }}</label>
                    <input
                        id="signup-phone"
                        type="tel"
                        prefix="+38"
                        mask="(000) 000 00 00"
                        [showMaskTyped]="true"
                        [dropSpecialCharacters]="true"
                        class="form-control"
                        [class.is-invalid]="
                            registerForm.controls['phone'] | isInvalid
                        "
                        [placeholder]="
                            'INPUT_PHONE_NUMBER_PLACEHOLDER' | translate
                        "
                        formControlName="phone"
                    />
                    <div class="invalid-feedback">
                        <div
                            *ngIf="
                                registerForm.controls['phone']
                                    | hasError : 'required'
                            "
                        >
                            {{ "ERROR_FORM_REQUIRED" | translate }}
                        </div>
                        <div
                            *ngIf="
                                registerForm.controls['phone']
                                    | hasError : 'phone'
                            "
                        >
                            {{
                                "ERROR_FORM_INCORRECT_PHONE_NUMBER" | translate
                            }}
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="sign-form-label" for="signup-email">{{
                        "INPUT_EMAIL_ADDRESS_LABEL" | translate
                    }}</label>
                    <input
                        id="signup-email"
                        type="email"
                        class="form-control"
                        [class.is-invalid]="
                            registerForm.controls['email'] | isInvalid
                        "
                        placeholder="customer@example.com"
                        formControlName="email"
                    />
                    <div class="invalid-feedback">
                        <div
                            *ngIf="
                                registerForm.controls['email']
                                    | hasError : 'required'
                            "
                        >
                            {{ "ERROR_FORM_REQUIRED" | translate }}
                        </div>
                        <div
                            *ngIf="
                                registerForm.controls['email']
                                    | hasError : 'email'
                            "
                        >
                            {{ "ERROR_FORM_INCORRECT_EMAIL" | translate }}
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="sign-form-label" for="signup-password">{{
                        "INPUT_PASSWORD_LABEL" | translate
                    }}</label>
                    <div
                        class="sign-form__password-container"
                        [class.is-invalid]="
                            registerForm.controls['password'] | isInvalid
                        "
                    >
                        <input
                            id="signup-password"
                            [type]="showSignUpPassword ? 'text' : 'password'"
                            class="form-control"
                            [class.is-invalid]="
                                registerForm.controls['password'] | isInvalid
                            "
                            [placeholder]="
                                'INPUT_PASSWORD_PLACEHOLDER' | translate
                            "
                            formControlName="password"
                        />
                        <button
                            type="button"
                            class="sign-form__toggle-password"
                            (click)="toggleSignUpPasswordVisibility()"
                        >
                            <fa-icon
                                *ngIf="!showSignUpPassword"
                                [icon]="faEye"
                            ></fa-icon>
                            <fa-icon
                                *ngIf="showSignUpPassword"
                                [icon]="faEyeSlash"
                            ></fa-icon>
                        </button>
                    </div>
                    <div class="invalid-feedback">
                        <div
                            *ngIf="
                                registerForm.controls['password']
                                    | hasError : 'required'
                            "
                        >
                            {{ "ERROR_FORM_REQUIRED" | translate }}
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="sign-form-label" for="signup-confirm">{{
                        "INPUT_PASSWORD_REPEAT_LABEL" | translate
                    }}</label>
                    <div
                        class="sign-form__password-container"
                        [class.is-invalid]="
                            registerForm.controls['confirmPassword'] | isInvalid
                        "
                    >
                        <input
                            id="signup-confirm"
                            [type]="showSignUpConfirm ? 'text' : 'password'"
                            class="form-control"
                            [class.is-invalid]="
                                registerForm.controls['confirmPassword']
                                    | isInvalid
                            "
                            [placeholder]="
                                'INPUT_PASSWORD_REPEAT_PLACEHOLDER' | translate
                            "
                            formControlName="confirmPassword"
                        />
                        <button
                            type="button"
                            class="sign-form__toggle-password"
                            (click)="toggleSignUpConfirmVisibility()"
                        >
                            <fa-icon
                                *ngIf="!showSignUpConfirm"
                                [icon]="faEye"
                            ></fa-icon>
                            <fa-icon
                                *ngIf="showSignUpConfirm"
                                [icon]="faEyeSlash"
                            ></fa-icon>
                        </button>
                    </div>
                    <div class="invalid-feedback">
                        <div
                            *ngIf="
                                registerForm.controls['confirmPassword']
                                    | hasError : 'required'
                            "
                        >
                            {{ "ERROR_FORM_REQUIRED" | translate }}
                        </div>
                        <div
                            *ngIf="
                                registerForm.controls['confirmPassword']
                                    | hasError : 'mustMatch'
                            "
                        >
                            {{
                                "ERROR_FORM_PASSWORD_DOES_NOT_MATCH" | translate
                            }}
                        </div>
                    </div>
                </div>
                <div class="form-group mb-0">
                    <button
                        type="submit"
                        class="btn btn-primary mt-4 mb-4 sign-form__submit-button"
                        [class.btn-loading]="registerInProgress"
                        (click)="register()"
                    >
                        {{ "BUTTON_REGISTER" | translate | uppercase }}
                    </button>
                </div>
            </form>
            <div class="sign-form__divider">
                {{ "TEXT_OR_SIGN_UP" | translate | uppercase }}
            </div>
            <div class="sign-form__service-buttons">
                <asl-google-signin-button
                    type="standard"
                    size="medium"
                    shape="pill"
                    theme="outline"
                    logo_alignment="center"
                    text="signup_with"
                ></asl-google-signin-button>
            </div>
        </ng-container>
    </div>
</div>
