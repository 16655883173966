import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { VehicleApi } from '../api';
import { ModificationVehicle, UserVehicle } from '../interfaces/vehicle';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from './login.service';

@Injectable({
    providedIn: 'root',
})
export class GarageService {
    private destroy$: Subject<void> = new Subject<void>();

    // private _currentVehicle$: BehaviorSubject<UserVehicle | null> = new BehaviorSubject<UserVehicle|null>(null);

    private _carsSubject$: BehaviorSubject<UserVehicle[]> = new BehaviorSubject<UserVehicle[]>([]);

    public cars$ = this._carsSubject$.asObservable();

    // public currentVehicle$ = this._currentVehicle$ as Observable<UserVehicle>;

    public hasCars$!: Observable<boolean>;

    // public addAvailable = false;

    private readonly localKey = 'garage';
    private readonly localKeyCurrent = 'currentVehicle';

    constructor(
        private api: VehicleApi,
        @Inject(PLATFORM_ID) private platformId: any,
        private toastr: ToastrService,
        private translate: TranslateService,
        private login: LoginService,
    ) {
        if (isPlatformBrowser(platformId)) {
            this._getGarage();

            this.hasCars$ = this.cars$.pipe(map(x => x.length > 0));

            this.login.userLogin$
                .pipe(takeUntil(this.destroy$))
                .subscribe(value => {
                    if (value) {
                        this._getGarage(true);
                    }
                });

            this.login.userLogout$
                .pipe(takeUntil(this.destroy$))
                .subscribe(value => {
                    if (value) {
                        this._resetLocalData();
                    }
                });
        }
    }

    private _getGarage(remote = false) {
        if (remote) {
            this.api.getGarage()
                .pipe(take(1))
                .subscribe(value => {
                    if (value.length) {
                        this._carsSubject$.next(value);
                        this._saveLocalStorage(value);
                        // this.loadCurrentVehicle();
                    } else {
                        this._resetLocalData();
                    }
                });
        } else {
            this._loadLocalStorage();
            // this.loadCurrentVehicle();
        }
    }

    private _resetLocalData() {
        // this.setCurrentVehicle(null);
        this._carsSubject$.next([]);
        this._saveLocalStorage(this._carsSubject$.value);
    }

    private _loadLocalStorage() {
        const items = localStorage.getItem(this.localKey);
        if (items) {
            this._carsSubject$.next(JSON.parse(items));
        }
    }

    private _saveLocalStorage(cars: ModificationVehicle[]) {
        localStorage.setItem(this.localKey, JSON.stringify(cars));
    }

    get cars(): UserVehicle[] {
        return this._carsSubject$.value;
    }

    // get currentVehicle(): UserVehicle | null {
    //     return this._currentVehicle$.value;
    // }
    //
    // setCurrentVehicle(uid: string | null) {
    //     if (isPlatformBrowser(this.platformId)) {
    //         const car = this._carsSubject$.value.find(value => value.uid === uid);
    //         if (car) {
    //             this._currentVehicle$.next(car);
    //         } else {
    //             this._currentVehicle$.next(null);
    //         }
    //         this.saveCurrentVehicle();
    //     }
    // }
    //
    // saveCurrentVehicle() {
    //     localStorage.setItem(this.localKeyCurrent, JSON.stringify(this._currentVehicle$.value));
    // }
    //
    // loadCurrentVehicle() {
    //     const vehicle = localStorage.getItem(this.localKeyCurrent);
    //     if (vehicle) {
    //         const vehicleObj = JSON.parse(vehicle) as UserVehicle | null;
    //         if (vehicleObj) {
    //             this.setCurrentVehicle(vehicleObj.uid);
    //         }
    //     }
    // }

    addCar(car: ModificationVehicle, setCurrent = true, plate = '', vin = '') {
        let vehicle = car as UserVehicle;
        vehicle.vin = vin;
        vehicle.plate = plate;

        if (this.login.isLoggedIn$.value) {
            this._carsSubject$.next([...this._carsSubject$.value, vehicle]);
        } else {
            this._carsSubject$.next([vehicle]);
        }

        this._saveLocalStorage(this._carsSubject$.value);

        // if (setCurrent) {
        //     this.setCurrentVehicle(vehicle.uid);
        // }

        if (this.login.isLoggedIn$.value) {
            this.api.addVehicle(vehicle.id, vehicle.uid, vin, plate).pipe(take(1)).subscribe();
            this.toastr.success(this.translate.instant('TEXT_ADD_CAR_SUCCESS'));
        }
    }

    removeCar(car: UserVehicle) {
        let values = this._carsSubject$.value.filter(value => value.uid !== car.uid);
        this._carsSubject$.next(values);
        // this.setCurrentVehicle(null);
        this._saveLocalStorage(this._carsSubject$.value);
        this.api.removeVehicle(car.id, car.uid).pipe(take(1)).subscribe();
    }

    saveCar(car: UserVehicle) {
        this._carsSubject$.next(this._carsSubject$.value);
        this._saveLocalStorage(this._carsSubject$.value);
        this.api.saveUserCarData(car).pipe(take(1)).subscribe();
    }
}
