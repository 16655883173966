<app-block-space layout="spaceship-ledge-height"></app-block-space>

<div class="block">
    <div class="container">
        <div class="mx-auto">
            <div class="d-flex flex-column align-items-center">
                <h1 class="text-secondary font-weight-bold mt-4">{{ 'HEADER_LOGIN'|translate }}...</h1>
            </div>
        </div>
    </div>
</div>

<app-block-space layout="before-footer"></app-block-space>
