<button class="contacts__button" type="button">
    <span class="contacts__button-icon"><app-icon icon="phone-20"></app-icon></span>
    <span class="contacts__button-title">{{ "TEXT_CONTACTS"|translate|uppercase }}</span>
    <span class="contacts__button-arrow"><app-icon icon="arrow-rounded-down-9x6"></app-icon></span>
</button>
<div class="contacts__menu">
    <div class="contacts__body">
        <ul class="contacts__list">
            <li *ngFor="let item of data?.messengers" class="contacts__item" (click)="onItemClick()">
                <a class="contacts__link" href="{{item.contact}}" target="_blank">
                    <span class="contacts__link-icon">
                        <app-icon *ngIf="item.category === 'viber'" icon="viber-20"></app-icon>
                        <app-icon *ngIf="item.category === 'telegram'" icon="telegram-20"></app-icon>
                    </span>
                    <span class="contacts__link-text">{{ item.name }}</span>
                </a>
            </li>
            <li *ngFor="let item of data?.phones" class="contacts__item" (click)="onItemClick()">
                <ng-container *ngIf="!data?.isB2B; else phoneB2B">
                    <span class="contacts__link-icon"><app-icon icon="phone-20"></app-icon></span>
                    <a href="tel:{{item.contact}}">{{item.name}}</a>
                </ng-container>
                <ng-template #phoneB2B>
                    <a class="contacts__link d-flex align-items-center" href="tel:{{item.contact}}">
                        <span class="contacts__link-icon"><app-icon icon="phone-20"></app-icon></span>
                        <div class="contacts__link-text">{{item.name}}</div>
                    </a>
                </ng-template>
            </li>
            <li class="contacts__item">
                <div class="contacts__schedule">
                    <span *ngFor="let item of company.contacts.hours">{{item}}</span>
                </div>
            </li>
        </ul>
    </div>
</div>
