import { Component, OnInit } from '@angular/core';
import { BlockSpaceComponent } from '../../modules/shared/components/block-space/block-space.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BlockCarPickerComponent } from '../../modules/blocks/block-car-picker/block-car-picker.component';
import { NgIf, UpperCasePipe } from '@angular/common';
import { FeaturedCars } from '../../interfaces/vehicle';
import { ActivatedRoute } from '@angular/router';
import { CarPickerComponent } from '../../modules/shared/components/car-picker/car-picker.component';
import { BlockHeaderComponent } from '../../modules/shared/components/block-header/block-header.component';
import { BreadcrumbItem } from '../../modules/shared/components/breadcrumb/breadcrumb.component';
import { UrlService } from '../../services/url.service';
import { RouteMetaService } from '../../services/route-meta.service';

export interface CarsData {
    cars?: FeaturedCars,
}

@Component({
    selector: 'app-cars',
    templateUrl: './cars.component.html',
    styleUrls: ['./cars.component.scss'],
    standalone: true,
    imports: [
        BlockSpaceComponent,
        TranslateModule,
        BlockCarPickerComponent,
        UpperCasePipe,
        CarPickerComponent,
        NgIf,
        BlockHeaderComponent,
    ],
})

export class CarsComponent implements OnInit {
    cars?: FeaturedCars;

    breadcrumbs: BreadcrumbItem[] = [];

    constructor(
        private route: ActivatedRoute,
        private translate: TranslateService,
        private url: UrlService,
        private meta: RouteMetaService,
    ) {}

    ngOnInit(): void {
        this.route.data.subscribe((data: CarsData) => {
            this.cars = data.cars;
        });

        this.breadcrumbs = [
            { label: this.translate.instant('LINK_HOME'), url: this.url.home() },
            { label: this.translate.instant('TEXT_ALL_CAR_BRANDS'), url: '' },
        ];

        this.meta.update({
            title: this.meta.generateTitle('TEXT_ALL_CAR_BRANDS'),
            keywords: [],
            description: '',
        });
    }
}
