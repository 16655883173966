<div class="container">
    <div class="block-header__body">
        <app-breadcrumb
            *ngIf="breadcrumb.length"
            class="block-header__breadcrumb"
            [items]="breadcrumb"
            [withPageTitle]="!!pageTitle"
            [afterHeader]="afterHeader"
        >
        </app-breadcrumb>
        <div class="block-header__title">
            <h1 *ngIf="pageTitle" #titleElement>{{ pageTitle }}</h1>
            <hr *ngIf="withDivider">
        </div>
    </div>
</div>
