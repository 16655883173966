import { Observable } from 'rxjs';
import { User } from '../../interfaces/user';
import { AddressData } from '../../interfaces/address';
import { JournalList, OrdersList } from '../../interfaces/list';
import { Order } from '../../interfaces/order';
import { JournalType } from '../../interfaces/journal';
import { Balance } from '../../interfaces/balance';
import { UploadSubscription } from '../../interfaces/upload-subscription';
import { B2bRequest } from '../../interfaces/b2b-request';
import { B2bData } from '../../interfaces/b2b-request-data';

export interface EditProfileData {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
}

export interface EditProfilePassword {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export interface GetOrdersListOptions {
    page?: number;
    limit?: number;
    sort?: string;
}

export interface GetJournalListOptions {
    page?: number;
    type: JournalType;
}

export interface EditAddressData extends AddressData {
    default: boolean;
}

export interface ResetPasswordData {
    token: string;
    newPassword: string;
}

export abstract class AccountApi {
    abstract user: User | null;

    abstract fullName: string | null;

    abstract user$: Observable<User | null>;

    abstract get noSyncCart(): boolean;

    abstract refreshToken(): Observable<string>;

    abstract signIn(email: string, password: string): Observable<User>;

    abstract signInWithGoogle(tokenId: string): Observable<User>;

    abstract set loggedInAsSocial(value: boolean);

    abstract signUp(firstName: string, lastName: string, phone: string, email: string, password: string): Observable<User>;

    abstract signOut(): Observable<void>;

    abstract loginAs(token: string, noSyncCart: boolean): Observable<User>;

    abstract editProfile(data: EditProfileData): Observable<User>;

    abstract editProfilePassword(data: EditProfilePassword): Observable<any>;

    abstract getOrdersList(options?: GetOrdersListOptions): Observable<OrdersList>;

    abstract getOrderByToken(token: string): Observable<Order>;

    abstract getJournal(options: GetJournalListOptions): Observable<JournalList>;

    abstract getBalance(): Observable<Balance>;

    abstract getB2bRequest(): Observable<B2bRequest>

    abstract sendB2bRequest(data: B2bData): Observable<B2bData>

    abstract sendPaymentInform(cardNumber: string, amount: string): Observable<boolean>;

    abstract printReconciliationAct(startDate: string, endDate: string): Observable<any>;

    abstract print(id: string): Observable<any>;

    abstract getUploadSubscription(): Observable<UploadSubscription>;

    abstract editUploadSubscription(data: UploadSubscription): Observable<UploadSubscription>;

    abstract forgotPassword(email: string): Observable<any>;

    abstract resetPassword(data: ResetPasswordData): Observable<User>;
}
