import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { WishlistService } from '../../services/wishlist.service';
import { AccountApi } from '../../api';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CartService } from '../../services/cart.service';
import { HeaderService } from '../../services/header.service';
import { TranslateService } from '@ngx-translate/core';
import { UrlService } from '../../services/url.service';
import { LoginService } from '../../services/login.service';
import { isPlatformBrowser } from '@angular/common';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    userName$: Observable<string | null> = this.account.user$.pipe(map(x => x ? `${x.firstName} ${x.lastName ? x.lastName : ''}` : null));

    departmentsLabel$!: Observable<string>;

    isPlatformBrowser = isPlatformBrowser(this.platformId);

    constructor(
        private account: AccountApi,
        private translate: TranslateService,
        public wishlist: WishlistService,
        public cart: CartService,
        public header: HeaderService,
        public url: UrlService,
        public loginService: LoginService,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
    }

    ngOnInit(): void {
        this.departmentsLabel$ = this.header.desktopLayout$.pipe(
            switchMap(layout => this.translate.stream(
                layout === 'spaceship' ? 'BUTTON_DEPARTMENTS' : 'BUTTON_DEPARTMENTS_LONG',
            )),
        );
    }
}
