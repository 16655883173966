import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DesktopHeaderVariant, HeaderService, MobileHeaderVariant } from '../../services/header.service';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { TranslateUrlService } from '../../services/translate-url.service';
import { LanguageService } from '../../modules/language/services/language.service';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { RouteMetaService } from '../../services/route-meta.service';

export interface RootComponentData {
    desktopHeader: DesktopHeaderVariant;
    mobileHeader: MobileHeaderVariant;
}


@Component({
    selector: 'app-root',
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.scss'],
})
export class RootComponent implements OnInit, OnDestroy {
    destroy$: Subject<void> =  new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        public header: HeaderService,
        public translateUrl: TranslateUrlService,
        public language: LanguageService,
        @Inject(DOCUMENT) private document: Document,
        private meta: RouteMetaService,
    ) { }

    ngOnInit(): void {
        const data$ = this.route.data as Observable<RootComponentData>;

        data$.pipe(
            takeUntil(this.destroy$),
        ).subscribe((data: RootComponentData) => {
            this.header.setDesktopVariant(data.desktopHeader || 'pargo/one');
            this.header.setMobileVariant(data.mobileHeader || 'one');
        });

        this.translateUrl.currentUrl$
            .pipe(takeUntil(this.destroy$))
            .subscribe(value => this.setLinkAlternateTags(value));

        this.translateUrl.currentLang$
            .pipe(takeUntil(this.destroy$))
            .subscribe(value => {
                this.document.documentElement.setAttribute('lang', value);
                this.meta.noindex(value === 'en');
            });
    }

    setLinkAlternateTags(currentUrl: string) {
        // Удалить все существующие теги <link rel="alternate">
        const existingTags = this.document.head.querySelectorAll('link[rel="alternate"]');
        existingTags.forEach(tag => {
            this.document.head.removeChild(tag);
        });

        // Добавить новые теги <link rel="alternate">
        for(const item of this.language.all) {
            if (item === this.language.default) {
                this.createLinkAlternateElement(item.code, `${environment.mainHost}${currentUrl}`);
                this.createLinkAlternateElement('x-default', `${environment.mainHost}${currentUrl}`);
            } else {
                this.createLinkAlternateElement(item.code, `${environment.mainHost}/${item.code}${currentUrl}`);
            }
        }
    }

    createLinkAlternateElement(code: string, href: string): void {
        const link = this.document.createElement('link');
        link.setAttribute('rel', 'alternate');
        link.setAttribute('hreflang', code);
        link.setAttribute('href', href);
        this.document.head.appendChild(link);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
