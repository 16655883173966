// noinspection JSUnusedGlobalSymbols
export const environment = {
    production: true,
    paypalClientId: '------------------',
    apiHost: 'https://shop.genstar.ua/api-v1',
    mainHost: 'https://pargo.com.ua',
    googleTagManagerId: 'GTM-MHD95LMC',
    googleMapKey: 'AIzaSyBMPsNPq0fdAuEZiZqcj6T07lUee--qx8I',
    recaptcha: {
        siteKey: '6LeSSsgpAAAAAAqRAGIOimlyHhNki1jELnfeoI1U',
    },
    googleOAuthClientId: '565261558928-3lksvjrra6sj8v56fcr1udbp9fh7142n.apps.googleusercontent.com',
};
