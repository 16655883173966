import { NgModule } from '@angular/core';

// modules (angular)
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// modules
import { AppModule } from './app.module';

// components
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
    imports: [
        // modules (angular)
        BrowserAnimationsModule,
        // modules
        AppModule,
        FontAwesomeModule,
    ],
    bootstrap: [AppComponent],
})
export class AppBrowserModule {}
