import { Component, HostBinding, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { NgForOf, NgIf } from '@angular/common';

export interface BreadcrumbItem {
    label: string;
    url: string;
}

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    standalone: true,
    imports: [
        RouterLink,
        LocalizeRouterModule,
        NgForOf,
        NgIf
    ]
})
export class BreadcrumbComponent {
    @Input() items: BreadcrumbItem[] = [];

    @Input() withPageTitle = false;

    @Input() afterHeader = true;

    @HostBinding('class.breadcrumb') classBreadcrumb = true;

    @HostBinding('attr.aria-label') attrAriaLabel = 'breadcrumb';

    constructor() { }
}
