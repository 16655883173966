import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { AccountApi } from '../../../../api';
import { mustMatchValidator } from '../../../../functions/validators/must-match';
import { catchError, filter, finalize, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginService } from '../../../../services/login.service';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import { GoogleLoginProvider, GoogleSigninButtonModule, SocialAuthService } from '@abacritt/angularx-social-login';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CheckboxModule } from '../../../checkbox/checkbox.module';
import { IconComponent } from '../icon/icon.component';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { HasErrorPipe } from '../../pipes/has-error.pipe';
import { IsInvalidPipe } from '../../pipes/is-invalid.pipe';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';

@Component({
    selector: 'app-sign-form',
    templateUrl: './sign-form.component.html',
    styleUrls: ['./sign-form.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        TranslateModule,
        CommonModule,
        FontAwesomeModule,
        CheckboxModule,
        RouterLink,
        GoogleSigninButtonModule,
        IconComponent,
        NgxMaskDirective,
        HasErrorPipe,
        IsInvalidPipe,
        LocalizeRouterModule,
    ],
    providers: [provideNgxMask()],
})

export class SignFormComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();

    showSignInPassword = false;
    showSignUpPassword = false;
    showSignUpConfirm = false;

    loginForm!: UntypedFormGroup;
    loginInProgress = false;

    registerForm!: UntypedFormGroup;
    registerInProgress = false;

    public readonly tabSignIn: string = 'tabSignIn';
    public readonly tabSignUp: string = 'tabSignUp';

    public currentTab: string | undefined;

    @HostBinding('class.sign-form') classSignForm = true;

    constructor(
        private fb: UntypedFormBuilder,
        private router: Router,
        private account: AccountApi,
        public modalService: LoginService,
        private socialAuthService: SocialAuthService,
    ) {
    }

    ngOnInit(): void {
        this.setCurrentTab(this.tabSignIn);

        this.loginForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]],
            remember: [false],
        });

        this.registerForm = this.fb.group({
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            phone: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', [Validators.required]],
        }, {
            validators: [mustMatchValidator('password', 'confirmPassword')],
        });

        this.socialAuthService.authState
            .pipe(takeUntil(this.destroy$), filter(value => value != null))
            .subscribe(value => {
                if (value.provider == GoogleLoginProvider.PROVIDER_ID) {
                    console.log('google log in')
                    this.loginWithGoogle(value.idToken);
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    setCurrentTab(tabName: string) {
        this.currentTab = tabName;
    }

    loginWithGoogle(tokenId: string) {
        this.loginInProgress = true;
        this.account.signInWithGoogle(tokenId)
            .pipe(
                finalize(() => this.loginInProgress = false),
                takeUntil(this.destroy$),
                catchError(err => {
                    this.account.loggedInAsSocial = false;
                    return this.loginFail(err)
                }),
            ).subscribe(() => {
            this.account.loggedInAsSocial = true;
            this.loginSuccess();
        });
    }

    login(): void {
        this.loginForm.markAllAsTouched();

        if (this.loginInProgress || this.loginForm.invalid) {
            return;
        }

        this.loginInProgress = true;

        this.account.signIn(
            this.loginForm.value.email,
            this.loginForm.value.password,
        ).pipe(
            finalize(() => this.loginInProgress = false),
            takeUntil(this.destroy$),
            catchError(err => this.loginFail(err)),
        ).subscribe(() => this.loginSuccess());
    }

    register(): void {
        this.registerForm.markAllAsTouched();

        if (this.registerInProgress || this.registerForm.invalid) {
            return;
        }

        this.registerInProgress = true;

        this.account.signUp(
            this.registerForm.value.firstName,
            this.registerForm.value.lastName,
            this.registerForm.value.phone,
            this.registerForm.value.email,
            this.registerForm.value.password,
        ).pipe(
            finalize(() => this.registerInProgress = false),
            takeUntil(this.destroy$),
            catchError(err => this.registerFail(err)),
        ).subscribe(() => this.loginSuccess());
    }

    loginSuccess() {
        this.modalService.close();
        this.router.navigate([this.router.url], {
            replaceUrl: true,
            skipLocationChange: false,
        }).then();
    }

    loginFail(err: any) {
        if (err instanceof HttpErrorResponse) {
            this.loginForm.setErrors({
                server: `ERROR_API_${err.error.message}`,
            });
        } else {
            alert(err);
        }
        return throwError(() => err);
    }

    registerFail(err: any) {
        if (err instanceof HttpErrorResponse) {
            this.registerForm.setErrors({
                server: `ERROR_API_${err.error.message}`,
            });
        } else {
            alert(err);
        }
        return throwError(() => err);
    }

    toggleSignInPasswordVisibility(): void {
        this.showSignInPassword = !this.showSignInPassword;
    }

    toggleSignUpPasswordVisibility(): void {
        this.showSignUpPassword = !this.showSignUpPassword;
    }

    toggleSignUpConfirmVisibility(): void {
        this.showSignUpConfirm = !this.showSignUpConfirm;
    }

    protected readonly faEye = faEye;
    protected readonly faEyeSlash = faEyeSlash;
}
