<div class="container">

    <app-block-header
        [pageTitle]="'TEXT_ALL_CAR_BRANDS'|translate|uppercase"
        [breadcrumb]="breadcrumbs || []"
        [withDivider]="false"
    ></app-block-header>

    <app-block-space layout="divider-nl"></app-block-space>

    <app-car-picker
        [featured]="false"
        [cars]="cars"
        [modelPicker]="false"
        [showPickerLabel]="false"
    >
    </app-car-picker>

    <app-block-space layout="before-footer"></app-block-space>

</div>

