import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountApi, ResetPasswordData } from '../../api';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { catchError, take } from 'rxjs/operators';
import { mustMatchValidator } from '../../functions/validators/must-match';
import { HasErrorPipe } from '../../modules/shared/pipes/has-error.pipe';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IsInvalidPipe } from '../../modules/shared/pipes/is-invalid.pipe';
import { throwError } from 'rxjs';
import { RouteMetaService } from '../../services/route-meta.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        HasErrorPipe,
        NgIf,
        TranslateModule,
        FontAwesomeModule,
        IsInvalidPipe,
    ],
})
export class ResetPasswordComponent implements OnInit {
    resetPasswordForm: FormGroup;
    token?: string;
    errorMessage: string | null = null;

    showPassword = false;
    showConfirmPassword = false;

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private account: AccountApi,
        private localize: LocalizeRouterService,
        private meta: RouteMetaService,
    ) {
        this.resetPasswordForm = this.fb.group({
                password: ['', [Validators.required, Validators.minLength(6)]],
                confirmPassword: ['', [Validators.required]],
            }, {
                validators: [
                    mustMatchValidator('password', 'confirmPassword'),
                ],
            },
        );
    }

    ngOnInit() {
        this.token = this.route.snapshot.paramMap.get('token') as string;
        this.meta.update({
            title: this.meta.generateTitle('TEXT_PASSWORD_RECOVERY'),
            keywords: [],
            description: '',
        });
    }

    onSubmit() {
        this.errorMessage = null;
        if (this.resetPasswordForm.valid) {
            const data: ResetPasswordData = {
                token: this.token!,
                newPassword: this.resetPasswordForm.value.password,
            };
            console.log(data)
            this.account.resetPassword(data)
                .pipe(take(1), catchError(error => {
                    this.errorMessage = `ERROR_API_${error.error.message}`
                    return throwError(()=>new Error(error));
                }))
                .subscribe(() => {
                    const route = this.localize.translateRoute('/') as string;
                    this.router.navigateByUrl(route).then();
                })
        }
    }

    protected readonly faEyeSlash = faEyeSlash;
    protected readonly faEye = faEye;
}
