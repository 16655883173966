import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LanguageService } from '../../modules/language/services/language.service';
import {
    CalcCartData,
    CartData, CartItem,
    CheckoutApi,
    CheckoutData,
    CheckoutFormData, CheckoutOrder,
    CheckoutUpdateItems,
    DeliveryPoint,
    DeliveryWarehouse,
    Payer,
} from '../base';
import { Observable } from 'rxjs';

@Injectable()
export class RestCheckoutApi extends CheckoutApi {
    url = `${environment.apiHost}/checkout`;

    constructor(private http: HttpClient, private language: LanguageService) {
        super();
    }

    private get lang(): string {
        return this.language.current.code;
    }

    getFormData(): Observable<CheckoutFormData> {
        const params = new HttpParams()
            .set('lan', this.lang);
        const url = `${this.url}/form-data`;
        return this.http.get<CheckoutFormData>(url, { params: params });
    }

    getStreets(term: string, methodCode: string, { cityRef = '' }): Observable<DeliveryPoint[]> {
        const params = new HttpParams()
            .set('term', term)
            .set('methodCode', methodCode)
            .set('cityRef', cityRef)
            .set('lan', this.lang);
        const url = `${this.url}/streets`;
        return this.http.get<DeliveryPoint[]>(url, { params: params });
    }

    getRegions(service: string): Observable<DeliveryPoint[]> {
        const params = new HttpParams()
            .set('service', service)
            .set('lan', this.lang);
        const url = `${this.url}/regions`;
        return this.http.get<DeliveryPoint[]>(url, { params: params });
    }

    getCities(service: string, { term = '', refRegion = '' }): Observable<DeliveryPoint[]> {
        const params = new HttpParams()
            .set('term', term)
            .set('refRegion', refRegion)
            .set('service', service)
            .set('lan', this.lang);
        const url = `${this.url}/cities`;
        return this.http.get<DeliveryPoint[]>(url, { params: params });
    }

    getWarehouses(cityRef: string, methodCode: string): Observable<DeliveryWarehouse[]> {
        const params = new HttpParams()
            .set('cityRef', cityRef)
            .set('methodCode', methodCode)
            .set('lan', this.lang);
        const url = `${this.url}/warehouses`;
        return this.http.get<DeliveryWarehouse[]>(url, { params: params });
    }

    getPayer(value:string, field:string): Observable<Payer> {
        const url = `${this.url}/search-payer`;
        const body = {
            value: value,
            field: field,
        };
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post<Payer>(url, JSON.stringify(body), { headers: headers });
    }

    override checkout(data: CheckoutData): Observable<CheckoutOrder | CheckoutUpdateItems> {
        const url = `${this.url}/submit`;
        const body = data;
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post<CheckoutOrder | CheckoutUpdateItems>(url, JSON.stringify(body), { headers: headers });
    }

    cart(data: CartData): Observable<CartData> {
        const url = `${this.url}/cart`;
        const body = data;
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post<CartData>(url, JSON.stringify(body), { headers: headers });
    }

    deleteCartItems(items: CartItem[]): Observable<CartData> {
        const url = `${this.url}/delete-items`;
        const body = {
            items,
        };
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post<CartData>(url, JSON.stringify(body), { headers: headers });
    }

    addCartItem(item: CartItem): Observable<CartData> {
        const url = `${this.url}/add-item`;
        const body = {
            productId: item.product.id,
            quantity: item.quantity,
        };
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post<CartData>(url, JSON.stringify(body), { headers: headers });
    }

    updateCartItems(items: CartItem[]): Observable<CartData> {
        const url = `${this.url}/update-items`;
        const body = {
            items,
        };
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post<CartData>(url, JSON.stringify(body), { headers: headers });
    }

    calcCart(items: CartItem[]): Observable<CalcCartData> {
        const url = `${this.url}/calc-cart`;
        const body = {
            items,
        }
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post<CalcCartData>(url, JSON.stringify(body), { headers: headers });
    }
}
