import { Injectable } from '@angular/core';
import { ContentApi } from '../base';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LanguageService } from '../../modules/language/services/language.service';
import { Observable } from 'rxjs';
import { SitePage } from '../../interfaces/site-page';
import { PostList } from '../../interfaces/list';
import { Post } from '../../interfaces/post';
import { ContactData } from '../../interfaces/contact';
import { SEOText } from '../../interfaces/seo-text';

@Injectable()
export class RestContentApi extends ContentApi {

    private url = environment.apiHost;

    private get lan(): string {
        return this.language.current.code;
    }

    constructor(private http: HttpClient, private language: LanguageService) {
        super();
    }

    getPage(slug: string): Observable<SitePage> {
        let url = `${this.url}/content/page`;
        const params = new HttpParams()
            .set('slug', slug)
            .set('lan', this.lan)
        return this.http.get<SitePage>(url, { params: params });
    }

    getBlog(page?: number, limit?: number): Observable<PostList> {
        let url = `${this.url}/content/blog`;
        let params = new HttpParams()
            .set('lan', this.lan)
        if (page) {
            params = params.append('page', page);
        }
        if (limit) {
            params = params.append('limit', limit);
        }
        return this.http.get<PostList>(url, { params: params });
    }

    getPost(slug: string): Observable<Post> {
        let url = `${this.url}/content/blog`;
        const params = new HttpParams()
            .set('lan', this.lan)
            .set('slug', slug)
        return this.http.get<Post>(url, { params: params });
    }

    getContactData(): Observable<ContactData> {
        let url = `${this.url}/content/contact`;
        const params = new HttpParams()
            .set('lan', this.lan)
        return this.http.get<ContactData>(url, { params: params });
    }

    getSeoText(index: string): Observable<SEOText> {
        let url = `${this.url}/content/seo`;
        const params = new HttpParams()
            .set('lan', this.lan)

        const body = {
            index,
        }
        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.http.post<SEOText>(url, JSON.stringify(body), { headers: headers, params: params });
    }
}
