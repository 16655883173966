import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountApi } from '../../api';
import { finalize, take } from 'rxjs/operators';
import { RouteMetaService } from '../../services/route-meta.service';
import { UrlService } from '../../services/url.service';
import { CartService } from '../../services/cart.service';

export type LoginAsNavigate = 'checkout';

@Component({
  selector: 'app-login-as',
  templateUrl: './login-as.component.html',
  styleUrls: ['./login-as.component.scss'],
})

export class LoginAsComponent implements OnInit {
    loading: boolean = false;

    constructor(
        private router: Router,
        private account: AccountApi,
        private route: ActivatedRoute,
        private meta: RouteMetaService,
        private url: UrlService,
        private cart: CartService,
    ) {}

    ngOnInit(): void {
        const token = this.route.snapshot.paramMap.get('token') as string;
        const noSyncCart = this.route.snapshot.queryParamMap.get('noSyncCart') === 'true';
        const navigate = this.route.snapshot.paramMap.get('navigate') as LoginAsNavigate;

        console.log('no sync cart', noSyncCart);
        console.log('navigate', navigate);

        this.loading = true;
        if (navigate === 'checkout') {
            this.cart.noSelectUpdate = true;
        }
        this.account.loginAs(token, noSyncCart)
            .pipe(take(1), finalize(() => this.loading = false))
            .subscribe(() => {
                if (navigate) {
                    if (navigate === 'checkout') {
                        this.router.navigateByUrl(this.url.checkout(), { replaceUrl: true }).then();
                        return;
                    }
                }
               this.router.navigateByUrl('/', { replaceUrl: true }).then();
            });

        this.meta.update({
            title: this.meta.generateTitle('HEADER_LOGIN'),
            keywords: [],
            description: '',
        });
    }
}
