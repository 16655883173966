<div class="pargo-footer">
    <div class="pargo-footer__widgets">
        <div class="container">
            <div class="pargo-footer__row">
                <div class="pargo-footer__logo">
                    <app-icon [icon]="'logo'"></app-icon>
                </div>
                <app-contacts-pargo [data]="contact.contactData$|async"></app-contacts-pargo>
                <app-links-pargo
                        class="pargo-footer__widget"
                        [header]="'FOOTER_NAVIGATION'|translate"
                        [links]="[
                            {title: 'LINK_ABOUT_US'|translate, url: '/site/about-us'},
                            {title: 'LINK_DELIVERY_INFORMATION'|translate, url: '/site/delivery'},
                            {title: 'LINK_SITE_WARRANTY_FULL'|translate, url: '/site/warranty'},
                            {title: 'LINK_BLOG'|translate, url: '/blog'},
                            {title: 'LINK_CONTACT_US'|translate, url: '/site/contact-us'},
                            {title: 'LINK_SITE_OFFER'|translate, url: '/site/offer'},
                            {title: 'LINK_PRIVACY_POLICY'|translate, url: '/site/privacy-policy'},
                        ]"
                ></app-links-pargo>
                <app-links-pargo
                        class="pargo-footer__widget"
                        [header]="'TEXT_CATEGORIES'|translate"
                        [links]="(categoriesLinks$|async) || []"
                ></app-links-pargo>

                <button *ngIf="(loginService.isLoggedIn$|async) === false" class="footer-login-button" (click)="loginService.open()">
                    <app-icon [icon]="'user-14'"></app-icon>
                    <span>{{'FOOTER_BUTTON_LOGIN'|translate|uppercase}}</span>
                </button>
                <button *ngIf="(loginService.isLoggedIn$|async) === true" class="footer-login-button px-5" (click)="loginService.logout()">
                    <span>{{'LINK_ACCOUNT_LOGOUT'|translate|uppercase}}</span>
                </button>
            </div>
        </div>
    </div>
    <div class="pargo-footer__bottom">
        <div class="container">
            <div class="pargo-footer__bottom-row">
                <div class="pargo-footer__copyright">
                    <!-- copyright -->
                    {{currentYear}} {{theme.name}}. Всі права захищені
                    <!-- copyright / end -->
                </div>
<!--                <app-social class="pargo-footer__widget"></app-social>-->
            </div>
        </div>
    </div>
</div>
