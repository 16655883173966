import { Component, Input, OnInit } from '@angular/core';
import { VehicleApi } from '../../../../api';
import { CarBrand, FeaturedCars } from '../../../../interfaces/vehicle';
import { take } from 'rxjs/operators';
import { Category } from '../../../../interfaces/category';
import { UrlService } from '../../../../services/url.service';
import { Brand } from '../../../../interfaces/brand';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-car-picker',
    templateUrl: './car-picker.component.html',
    styleUrls: ['./car-picker.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        RouterLink,
        LocalizeRouterModule,
        TranslateModule,
    ],
})
export class CarPickerComponent implements OnInit {
    @Input() cars?: FeaturedCars;

    currentLetter?: string;

    @Input() featured: boolean = true;

    @Input() category?: Category | null;

    @Input() productBrand?: Brand;

    @Input() modelPicker: boolean = true;

    @Input() showPickerLabel: boolean = true;

    constructor(private api: VehicleApi, public url: UrlService) {
    }

    ngOnInit(): void {
        if (this.featured) {
            this.api.getFeaturedCars()
                .pipe(take(1))
                .subscribe(value => {
                    this.cars = value;
                    this.setInitLetter();
                });
        } else if (this.cars) {
            this.setInitLetter();
        }
    }

    setInitLetter() {
        this.currentLetter = this.brandLetters[0];
    }

    /**
     * Returns an array of brand letters based on the available cars.
     *
     * @returns {string[]} An array of brand letters.
     */
    get brandLetters(): string[] {
        if (this.cars) {
            return Object.keys(this.cars);
        }
        return [];
    }

    /**
     * Sets the current letter.
     *
     * @param {string} newLetter - The new letter to set as the current letter.
     * @return {void}
     */
    setCurrentLetter(newLetter: string): void {
        this.currentLetter = newLetter;
    }

    /**
     * Retrieves an array of car brands that start with the given letter.
     *
     * @param {string} letter - The letter to filter the car brands by.
     * @return {CarBrand[]} - An array of car brands that start with the given letter. Returns an empty array if no matching brands are found.
     */
    getBrandCars(letter: string): CarBrand[] {
        if (this.cars && this.cars[letter]) {
            return this.cars[letter];
        }
        return [];
    }

    /**
     * Returns an array of all car brands.
     *
     * @return {CarBrand[]} - An array of all car brands.
     */
    get allBrands(): CarBrand[] {
        if (this.cars) {
            return Object.values(this.cars).reduce((acc, curr) => acc.concat(curr), []);
        }
        return [];
    }
}
